@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;600;700;900&display=swap");

body {
  background-color: #0f172a;
  font-family: "Roboto", sans-serif;

  .MuiPaper-root {
    background-color: #191a32;

    .MuiBox-root {
      width: 520px;

      @media only screen and (min-width: 600px) and (max-width: 870px) {
        width: 360px;
      }

      @media only screen and (max-width: 600px) {
        width: 100vw;
      }
    }
  }

  .ub_pseudo_background:nth-child(even) {
    background-color: RGB(39, 44, 82) !important;
  }

  .custom_phone_input_style {
    input {
      background-color: transparent;
      outline: none;
      border-bottom: 1px solid #8789c3;
    }
  }

  .custom_otp_input {
    input {
      background-color: transparent;
      border: 2px solid white;
      color: white;
      width: 50px !important;
      height: 50px;
      margin: 5px 15px;
      border-radius: 50%;
      // padding: 24px;
    }
  }
}

.scrollhide::-webkit-scrollbar {
  /* display: none; */
  /* background-color: red; */
  width: 0px !important;
}

* {
  margin: 0;
  padding: 0;
}

#score_table {
  margin-top: 10px;
}
table {
  width: 100%;
  text-align: left;
  font-family: inter;
  margin-bottom: 6px;
  border-spacing: 0 15px;
  table-layout: fixed;
}
th:first-child {
  // width: 50%;
  text-align: left;
  padding-left: 4px;
}

table th {
  font-size: 16px;
}
tr:nth-child(even) {
  background: #6ff8e837;
}

th {
  width: 10%;
}
th:last-child {
  padding-right: 4px;
}
td {
  text-align: right;
}
td:first-child {
  text-align: left;
  padding-left: 4px;
}

td {
  width: 10%;
  font-weight: 300;
  padding: 2px 0;
}
#table-headers {
  width: 100%;
}
td,
th {
  border-top: 1px solid #c3c4c7;
  padding: 5px 5px;
  font-size: 12px;
  font: 300;
  border: none;
}

th {
  text-align: right;
  color: #1e1e1e;
}

th {
  background-color: #d9d9d9;
}

@media screen and (max-width: 600px) {
  td,
  th {
    text-align: center;
  }
}
.my-toast-container-colored {
  background-color: #555;
  color: #fff;
}

.my-toast-body-colored {
  background-color: #555;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Modal Styles */
.modal {
  /* Other modal styles */
  opacity: 0; /* Initial opacity set to 0 */
  animation: fade-in 0.2s ease-in forwards; /* Apply fade-in animation */
}

@media (max-width: 480px) {
  .mobile-width {
    max-width: 350px;
  }
}

.ub-popup,
.ub-popup-ad-container {
  align-items: center;
  overflow: hidden;
}
.ub-popup-ad-container {
  display: none;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed !important;
  pointer-events: auto;
  // display: flex;
  justify-content: center;
  // flex-direction: column;
  // justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  z-index: 2147483647;
}

.ub-popup-ad-container .ub-popupcontent {
  height: 100vh !important;
}
#ub-anchor-adcontainer{
  max-height: 50px !important;
  height: 50px !important;
}
#ub-anchor-adcontainer .adsbygoogle {
  max-height: 50px !important;
  height: 50px !important;
  width: 320px !important;
}
.ub-popupcontent {
  min-width: 700px;
  position: absolute;
  padding: 10px;
  border: 1px solid #888;
  border-radius: 5px;
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 768px) {
  // .ub-popup-ad-container {
  //   justify-content: flex-start; /* Adjust as needed */
  // }

  .ub-popupcontent {
    min-width: 350px;
  }
}
.ub-popup {
  width: auto !important;
  height: auto !important;
  margin: 0 auto;
}
.close_ub-popup {
  background-color: #ebebeb;
  color: #000;
  cursor: pointer;
  font-family: arial;
  font-weight: 700;
  position: relative;
  margin-bottom: 10px;
  font-size: 25px;
  line-height: 25px;
  width: 30px;
  height: 30px;
  z-index: 2147483647;
  text-align: center;
  border: 2px solid #000;
  border-radius: 20%;
}

.ad-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background
  padding: 20px;
  z-index: 10; /* Ensure modal stays on top */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ad-modal-content {
  margin-bottom: 10px;
}

.ad-modal-close {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 5px 10px;
  font-weight: bold;
}

// .ub-anchor {
//   width: max-content;
//   z-index: 2147483647;
//   padding-top: 4px;
//   max-height: 50px !important;
//   height: 50px  !important;
// } 


// #anchorAdsGoogle {
//   display: block !important;
//   width: 320px !important;
//   height: 50px !important;
//   max-height: 50px !important;
// }



// @media (max-width: 600px) {
//   #anchorAdsGoogle {
//    /* Adjust as needed */
//    width: 100%;
//     height: 50px !important;
//     max-height: 50px !important;
//   }
//   #popAdGoogle {
//     width: 350px;

//   }
// }

// #anchoradgoogle {
//   height: 50px !important;
//   max-height: 50px !important;
//   width: 320px;
// }


